html, body {
  min-width: 0;
}

body {
  background: url(./noise-bg.jpg) repeat;
  color: #5a5a5a;
}

p {
  line-height: 1.35;
  margin-bottom: 14px;
}

.centered-container {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: auto;
  white-space: nowrap;
}

.centered-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  white-space: normal;
}

.centered {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  white-space: normal;
}

.content {
  font-size: 26px;
}

h1 {
  font-size: 250%;
  font-weight: 100;
  margin-bottom: 0.7em;
  color: #444;
}

.gray-404 {
  font-size: 152px;
  color: transparent;
  text-shadow: 0 2px 3px #e5e5e5, 0 0 0 rgba(0, 0, 0, 0.2);
  margin-top: 0.5em;
}

.error {
  font-style: italic;
  font-size: 80%;
}

.centered {
  max-width: 500px;
}

.complaint-link {
  text-decoration: none;
  border-bottom: 1px dashed #1eb8b9;
}
.complaint-link:hover, .complaint-link:active {
  border-bottom: 1px dashed #f0662f;
}

.complaint {
  margin-top: 2em;
  font-size: 70%;
  font-style: italic;
  color: #777;
  display: none;
}
.complaint.visible {
  display: block;
}

textarea {
  min-width: 70%;
  height: 50px;
}

.svg-500 {
  overflow: visible;
  position: absolute;
  left: 50%;
  top: 50%;
}

.orbit {
  fill: none;
  stroke: #ddd;
  stroke-dasharray: 20 20;
}

.satellite-body {
  fill: #e8e8e8;
}

.satellite-head {
  fill: #adc48e;
}

.waves {
  stroke: #c0c0c0;
  stroke-width: 1px;
  fill: none;
}
.waves ellipse {
  opacity: 0;
}

.bubble {
  fill: rgba(255, 255, 255, 0.7);
  stroke: #808080;
  stroke-width: 1px;
}

.bubble-text {
  font-family: sans;
  font-size: 10px;
  fill: #444;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg) translate(0, -400px);
            transform: rotate(0deg) translate(0, -400px);
  }
  to {
    -webkit-transform: rotate(360deg) translate(0, -400px);
            transform: rotate(360deg) translate(0, -400px);
  }
}
@-webkit-keyframes spin-saying {
  from {
    -webkit-transform: rotate(0deg) translate(0, -400px) rotate(0deg) translate(20px, -50px);
            transform: rotate(0deg) translate(0, -400px) rotate(0deg) translate(20px, -50px);
  }
  to {
    -webkit-transform: rotate(360deg) translate(0, -400px) rotate(-360deg) translate(20px, -50px);
            transform: rotate(360deg) translate(0, -400px) rotate(-360deg) translate(20px, -50px);
  }
}
.satellite {
  -webkit-animation-name: spin;
}

.saying {
  -webkit-animation-name: spin-saying;
}

.satellite, .saying {
  -webkit-animation-duration: 60s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes saying-scale {
  0% {
    -webkit-transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
  }
  12% {
    -webkit-transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
  }
  15% {
    -webkit-transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
  }
  25% {
    -webkit-transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
  }
  35% {
    -webkit-transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(1) translate(2.5px, -45px);
  }
  38% {
    -webkit-transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
  }
  100% {
    -webkit-transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
            transform: translate(-2.5px, 45px) scale(0) translate(2.5px, -45px);
  }
}
.saying-inner {
  -webkit-animation-name: saying-scale;
  -webkit-animation-duration: 14s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@-webkit-keyframes satellite-inner {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.satellite-inner {
  -webkit-animation-name: satellite-inner;
  -webkit-animation-duration: 20s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}